@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{

    @font-face {
        font-family: iransans;
        src: url("/public/assets/fonts/IRANSansX-Regular.woff") format("woff");
        font-weight: normal;
        font-style: normal; }
    @font-face {
        font-family: iransans;
        src: url("../public/assets/fonts/IRANSansX-Bold.woff") format("woff");
        font-weight: bold;
        font-style: normal; }
    @font-face {
        font-family: iransans;
        src: url("/public/assets/fonts/IRANSans_Black.ttf") format("truetype");
        font-weight: 900;
        font-style: normal; }
    @font-face {
        font-family: iransans;
        src: url("/public/assets/fonts/IRANSans_Medium.ttf") format("truetype");
        font-weight: 500;
        font-style: normal; }
    @font-face {
        font-family: iransans;
        src: url("/public/assets/fonts/IRANSans_Light.ttf") format("truetype");
        font-weight: 300;
        font-style: normal; }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        @apply appearance-none m-0 hidden
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
    background-color: #1b1b6c;
    transition: background-color 5000s ease-in-out 0s;
    }

}  

@layer components {
    
    body{
        @apply bg-custom-gray font-iransans
    }

    body.modal-opened{
        overflow: hidden !important;
    }

    .btn{
        @apply text-white px-6 py-3 rounded-2xl bg-primary flex items-center justify-center
    }

    .main-menu-link.active{
        @apply lg:!text-white text-primary lg:!bg-primary lg:border-primary/10 
    }

    .main-menu-link.active .main-menu-link-icon{
        @apply lg:!text-white text-primary group-hover:lg:!text-white
    }

    .main-menu-item#home-menu-item{
        @apply lg:order-1
    }

    .main-content { 
        @apply mt-[88px] w-full lg:mb-4 mb-8
    }

    .backdrop{
        @apply bg-black/60 w-full h-full fixed top-0 left-0 z-30
    }

    .header-btn{
        @apply profile-btn w-8 h-8 bg-primary text-white rounded-lg shadow-custom-lg outline outline-2 outline-primary/20 flex items-center justify-center relative before:absolute before:top-0 before:left-0 before:h-full before:w-0 before:bg-white/[0.06] before:hover:w-full before:duration-300
    }

    .collapse-content{
        transition: height 0.5s ease;
    }

    .contact-item:nth-last-child(2) .contact-item-dropdown{
        @apply md:!top-0
    }

    .text-sm-13px{
        font-size: 13px;
    }

    .text-xs-12px{
        font-size: 12px;
    }

    .text-xs-10px{
        font-size: 10px;
    }

    input:checked ~ .dot-wrapper{
        @apply bg-primary
    }
    
    input:checked ~ .dot {
        transform: translateX(140%);
        /* background-color: #6b21f3; */
    }

    .small-switch input:checked ~ .dot {
        transform: translateX(174%);
    }

    .h-4\.5{
        height: 18px;
    }

    .w-4\.5{
        width: 18px;
    }

}

.profile-btn,
.navbar-top-box {
    background-image: url('/public/assets/images/pattern/01.png');
}

.bg-pattern-blue{
    background-image: url('/public/assets/images/pattern/02.svg');
    background-size: cover;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.profile-red{
    @apply text-rose-600 bg-rose-100 border-rose-200
}

.profile-blue{
    @apply text-blue-600 bg-blue-100 border-blue-200
}

.profile-green{
    @apply text-green-600 bg-green-100 border-green-200
}

.profile-orange{
    @apply text-orange-600 bg-orange-100 border-orange-200
}

.profile-purple{
    @apply text-purple-600 bg-purple-100 border-purple-200
}

.banner .swiper-wrapper{
    max-height: 380px;
}   

.swiper-pagination-bullet{
    width: 9px;
    height: 9px;
} 

.swiper-pagination-bullet.swiper-pagination-bullet-active{
    width: 22px;
    border-radius: 6px;
    background-color: #f08017;
}

.partners-swiper .swiper-slide.swiper-slide-active img.grayscale{
    filter: grayscale(0) !important;
    opacity: 1 !important;
}

.swiper-clock .swiper-slide.swiper-slide-active{    
    opacity: 1;
}

.overflow-blur{
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0.01) 0%, rgb(245, 246, 244) 70%);
}

.shadow-percentage{
    box-shadow: 10px 10px 10px -1px rgba(0, 0, 0, 0.15),
    -10px -10px 10px -1px rgba(255, 255, 255, 0.95);
}

.shadow-percentage-inner{
    box-shadow:  inset 5px 5px 6px -1px rgba(0, 0, 0, 0.2),
    inset -5px -5px 6px -1px rgba(255, 255, 255, 0.95),
    -0.5px -0.5px 0px rgba(255, 255, 255, 1),
    0.5px 0.5px 0px rgba(0, 0, 0, 0.07),
    0 12px 10px -10px rgba(0, 0, 0, 0.05);
}