em-emoji-picker {
    position: absolute !important;
    left: 0;
    bottom: calc(100% + 8px);
    max-height: 150px;
    z-index: 2;
}

@media (max-width: 1049px){
    
    em-emoji-picker {
        left: 12px;
    }

}